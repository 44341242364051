import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private file: File;

  get getArquivo() {
    return this.file
  }

  set setArquivo(file: File) {
    this.file = file
  }
}